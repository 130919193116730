<template>
    <div class="common_content contact_box">
        <div class="contact_img_box animated headShake">
        <img class="contact_img" src="../../assets/joinUs/join_us.png"/>
        <p class="">联系方式 <span><br/>/  CONTACT US</span></p>
        </div>
        <div class="c_flex">
            <div class="flex_item animated fadeInDown" style="flex:2">
                <h1>琪尔特集团有限公司</h1>
                <div class="b_line"></div>
                <ul>
                    <li v-for="item in sets" :key="item.id">{{item.name}}：{{item.value}}</li>
                    <!-- <li>服务时间：8:30-18:00</li>
                    <li>联系邮箱：qierte@qet.com</li>
                    <li>地址：福建省晋江市阳光东路湖景商务大厦A栋</li> -->
                </ul>
                <img class="contact_img_qrcode" src="../../assets/joinUs/qrcode_2.png"/>
            </div>
            <div class="flex_item" style="flex:3">
                <img class="contact_img_map animated bounceInRight" src="../../assets/joinUs/map.png"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            sets: [],
        }
    },
    created() {
        this.getSet();
    },
    methods:{
         // 获取
        getSet() {
            let cond= {
                params:{
                    
                }
            };
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$http.get(this.$apiUrl.common.set,cond).then((res) => {
                this.sets = res.settingList.filter(item => item.keyword !=='beianhao');
                loading.close();
            }).catch(()=>{
                loading.close();
            });
        },
    }
}
</script>
<style scoped>
.common_content{
    margin-left: 312px;
    margin-top: 4vh;
}
.contact_box{
    margin-right: 156px;
}

.contact_img{
    width: 100%;
}
.contact_img_map{
    width: 90%;
    float: right;
}
.contact_img_qrcode{
    width: 35%;
}
.c_flex{
    display: flex;
    justify-content: space-between;
    margin-top: 96px;
}
.flex_item{
    
}
.flex_item h1{
    padding: 12px 0;
    margin: 0;
    font-size: 30px;
}
.b_line{
    width: 36px;
    height: 2px;
    background: #EE660E;
}
.flex_item ul{
    list-style: none;
    padding: 0;
}
.flex_item ul li{
font-size: 14px;
font-family: AlibabaPuHuiTiR;
font-weight: 400;
color: #000000;
line-height: 2;
}
.contact_img_box{
    position: relative;
  
}
.contact_img_box p{
font-size: 46px;
font-family: AlibabaPuHuiTiB;
font-weight: bolder;
color: #FFFFFF;
position: absolute;
    top: 0;
    left: 50%;
    top: 38%;
    transform: translate(-50%, -50%);
}
.contact_img_box span{
    font-size: 25px;
    color: #EE660E;
}
@media screen and (max-width:1500px) {
   .contact_box{
        margin-right: 50px;
    }
    .flex_item ul li{
        font-size: 14px;
        line-height: 1.8;
    }
    .flex_item h1{
        font-size: 24px;
    }
    .contact_img_box p{
font-size: 36px;
}
.contact_img_box span{
    font-size: 16px;
}
}
</style>